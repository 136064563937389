const language = {
  VN: {
    btnCancel: 'Quay lại',
    btnSave: 'Lưu',
    question: 'Câu hỏi',
    answer: 'Câu trả lời',
    title: 'Tiêu đề',
    subTitle: 'Tiêu đề phụ',
    summary: 'Tóm tắt',
    detail: 'Chi tiết',
    author: 'Tác giả',
    date: 'Ngày',
    name: 'Tên',
    image: 'Hình',
    value: 'Giá trị',
    unit: 'Đơn vị',
    price: 'Giá',
    comment: 'Nhận xét',
    position: 'Chức vụ',
    priceSale: 'Giá bán',
    priceDisplay: 'Giá hiển thị',
    error: {
      require : 'Nhập dữ liệu cho các field (*)',
      duplicate: '{%field%} bị trùng'
    },
    day: {
      0:'Chủ nhật',
      1:'Thứ hai',
      2:'Thứ ba',
      3:'Thứ tư',
      4:'Thứ năm',
      5:'Thứ sáu',
      6:'Thứ bảy'
    }
  },
  EN: {
    btnCancel: 'Cancel',
    btnSave: 'Save',
    question: 'Question',
    answer: 'Answer',
    title: 'Title',
    subTitle: 'Subtitle',
    summary: 'Summary',
    detail: 'Detail',
    author: 'Author',
    date: 'Date',
    name: 'Name',
    image: 'Image',
    value: 'Value',
    unit: 'Unit',
    price: 'Price',
    comment: 'Comment',
    position: 'Position',
    priceSale: 'Actual price',
    priceDisplay: 'Price will display',
    error: {
      require : 'Please fill all required field (*)',
      duplicate: '{%field%} is duplicated'
    },
    day: {
      0:'Sunday',
      1:'Monday',
      2:'Tuesday',
      3:'Wednesday',
      4:'Thursday',
      5:'Friday',
      6:'Saturday'
    }
  },
  KR: {
    btnCancel: '취소',
    btnSave: '구하다',
    question: '질문',
    answer: '답변',
    title: '표제',
    subTitle: '부제',
    summary: '개요',
    detail: '세부 묘사',
    author: '저자',
    date: '날짜',
    name: '이름',
    image: '그림',
    value: '가치',
    unit: '단위',
    price: '가격',
    comment: '논평',
    position: '위치',
    priceSale: '실제 판매 가격',
    priceDisplay: 'Giá hiển thị',
    error: {
      require : '모든 필수 입력란을 작성하십시오. (*)',
      duplicate: '{%field%} bị trùng'
    },
    day: {
      0:'Sunday',
      1:'Monday',
      2:'Tuesday',
      3:'Wednesday',
      4:'Thursday',
      5:'Friday',
      6:'Saturday'
    }
  }
};

export default language;

// 'use strict';

// import Vietnamese from '../assets/languages/vietnamese.json';
// import English from '../assets/languages/english.json';

// var data;

// class Language {
//   constructor() {}
//   init() {
//     var current = JSON.parse(localStorage.getItem('language'));
//     if (current===undefined||current===null) {
//       current = 'vietnamese';
//     }

//     this.loadLanguage(current);

//   }
//   loadLanguage(code){
//     //console.log(Vietnamese);
//     switch(code){
//       case 'vietnamese':
//         data = Vietnamese;
//         break;
//       case 'english':
//         data = English;
//         break;
//       default:
//         data = Vietnamese;
//         break;
//     }
//   }
//   getLanguage(){
//     return data;
//   }
// }

// export default Language;