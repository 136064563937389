import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import AttachmentIcon from '@material-ui/icons/Attachment';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';


//import classNames from 'classnames';
import Utils from '../../api/api';
import theme, {styles} from '../../layout/theme';

class UploadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      progress: 0,
      buffer: 0,
      tests: {
        filereader: typeof FileReader != 'undefined',
        dnd: 'draggable' in document.createElement('span'),
        formdata: !!window.FormData,
        progress: "upload" in new XMLHttpRequest()
      }
    }
  }
  componentDidMount() {
    var holder = document.getElementsByClassName('upload-zone')[0],
        $this = this;
    if (this.state.tests.dnd) {
      holder.ondragover = function () { this.classList.add('hover'); return false; };
      holder.ondragend = function () { this.classList.remove('hover'); return false; };
      holder.ondrop = function (e) {
        e.preventDefault();
        this.classList.remove('hover');
        $this.uploadFile(e.dataTransfer.files);
      }
    }
  }
  onFileChange = (event) => {
    //console.log(event);
    this.uploadFile(event.target.files);
  }
  uploadFile (files) {
    //var holder = document.getElementsByClassName('upload-zone')[0];
    var formData = this.state.tests.formdata ? new FormData() : null;
    var $this = this,
        filetypes = $this.props.acceptedTypes;
    
    for (var i = 0; i < files.length; i++) {
      if(files[i].type) {
        //console.log(files[i].type);
        if(filetypes.indexOf(files[i].type)!==-1){
          if (this.state.tests.formdata) formData.append('file', files[i]);
        }else{
          alert(files[i].name + '\nFile type not supported\nOnly support '+$this.props.acceptedTypes+'!!!');
          $this.props.onComplete(JSON.parse({status:'failed',result:[]}));
          $this.handleCloseProgressModal();
        }
      } else {
        let t = files[i].name.split('.').pop();
        if(filetypes.indexOf('.'+t)!==-1){
          if (this.state.tests.formdata) formData.append('file', files[i]);
        }else{
          alert(files[i].name + '\nFile type not supported\nOnly support '+$this.props.acceptedTypes+'!!!');
          $this.props.onComplete(JSON.parse({status:'failed',result:[]}));
          $this.handleCloseProgressModal();
        }
      }
    }
    // now post a new XHR request
    if (this.state.tests.formdata&&formData!==null) {
      var xhr = new XMLHttpRequest();

      $this.handleOpenProgressModal();
      xhr.onload = function() {
        //progress.value = progress.innerHTML = 100;
        $this.setState({progress:100,buffer:100});
        $this.props.onComplete(JSON.parse(xhr.response));
        $this.handleCloseProgressModal();
      };

      if (this.state.tests.progress) {
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            var complete = (event.loaded / event.total * 100 | 0);
            $this.setState({progress:complete,buffer:complete+10});
          }
        }
      }

      xhr.open('POST', Utils.serverAPI() + '/api/uploads/'+encodeURIComponent(this.props.target));
      xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user_token'));
      xhr.send(formData);

    }
  }
  handleOpenProgressModal = () => {
    this.setState({ open: true });
  }
  handleCloseProgressModal = () => {
    this.setState({ open: false });
  }
  render() {
    const $this = this;
    const classes = this.props.classes;
    const progress = this.state.progress;
    const buffer = this.state.buffer;
    return (
      <div className={'upload-zone '+(this.props.className!==undefined?this.props.className:'')}>
        {this.props.children}
        {
          this.props.type==='file'?(
            <React.Fragment>
              <TextField
                className='fake-inputfile'
                placeholder={$this.props.placeholder||''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachmentIcon></AttachmentIcon>
                    </InputAdornment>
                  ),
                }}
              />
              <input onChange={this.onFileChange} type='file' accept={this.props.acceptedTypes.join()}></input>
            </React.Fragment>
          ):''
        }
        <Dialog
          open={this.state.open}
          onClose={this.handleCloseProgressModal}
        >
          <div className={classes.dialogProgress}>
            <LinearProgress className={classes.progressUpload} color="secondary" variant="buffer" value={progress} valueBuffer={buffer} />
          </div>
        </Dialog>
      </div>
    )
  }
}

UploadItem.propTypes = {
  classes: PropTypes.object.isRequired
};
UploadItem.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(UploadItem);
