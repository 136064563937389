import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import GavelIcon from "@material-ui/icons/Gavel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import AttachmentIcon from "@material-ui/icons/Attachment";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import XLSX from "xlsx";
import RootRef from "@material-ui/core/RootRef";

import classNames from "classnames";

import theme, { styles } from "../../layout/theme";
import Utils from "../../api/api";
import WebConfig from "../../api/config";

let config = WebConfig.production;
if (window.location.hostname.indexOf("local") !== -1) {
  config = WebConfig.development;
}

class ReactTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cache: [],
      data: [],
      failed: false,
      message: "",
      activeStep: 0,
      totalSteps: 1,
      totalRecord: 0,
      lastId: 0,
      firstId: 0,
      keyword: "",
      filter: undefined,
      sorts: undefined,
      ranges: [],
      ids: [],
      itemPerPage: config.itemPerPage,
      loading: false,
      init: false,
      selectedAll: false,
      numSelected: 0,
      openMessage: false,
      source: null,
      oldIndex: null,
      newIndex: null,
      bulkSelected: "",
      exportType: 0,
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    let $this = this;
    $this.setState({ loading: true });

    let s = $this.props.apiSort;

    if (s === "" || s === undefined) {
      s = null;
    }

    Utils.getListDataSortWithFilter(
      $this.props.apiTable,
      1,
      $this.state.itemPerPage,
      "0,0",
      null,
      s,
      1,
      false,
      (data) => {
        $this.handleData(data);
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let $this = this;
    let filter = $this.state.filter !== undefined ? $this.state.filter : undefined;
    let sorts = $this.state.sorts !== undefined ? $this.state.sorts : undefined;

    if (nextProps.apiFilter !== null) {
      if (filter !== nextProps.apiFilter) {
        $this.setState(
          { filter: nextProps.apiFilter, ranges: [] },
          () => {
            $this.handleSearch();
          }
        );
      }
    }

    if (nextProps.apiSort !== null) {
      if (sorts !== nextProps.apiSort) {
        console.log(nextProps);
        $this.setState({ sorts: nextProps.apiSort, ranges: [] }, () => {
          $this.handleSearch();
        });
      }
    }

    if (nextProps.keyword !== $this.state.keyword) {
      console.log(nextProps);
      $this.setState({ keyword: nextProps.keyword, ranges: [] }, () => {
        if ($this.state.keyword.length > 2) {
          $this.handleSearch();
        } else if ($this.state.keyword.length === 0) {
          $this.handleSearch();
        }
      });
    }
  }

  handleData(data) {
    let $this = this;
    if (data.status === "success" && data.results.length) {
      let first = data.results[0]._id;
      let last = data.results[data.results.length - 1]._id;
      let pages = Math.ceil(data.total / $this.state.itemPerPage);

      data.results.map((x) => {
        x.selected = false;
      });

      let cache = $this.state.cache,
        query = {
          itemPerPage: $this.state.itemPerPage,
          firstId: first,
          lastId: last,
        };

      if (!$this.handleFindCache(query)) {
        cache.push({
          query: query,
          data: data.results,
        });
      }

      $this.setState({
          data: data.results,
          cache: cache,
          totalRecord: data.total,
          totalSteps: pages,
          lastId: last,
          firstId: first,
          selectedAll: false,
          numSelected: 0,
        },
        () => {
          let arr = $this.state.ranges,
            ids = $this.state.ids;

          if (arr.length !== $this.state.totalSteps) {
            arr = [];
            for (let i = 0, len = $this.state.totalSteps; i < len; i += 1) {
              arr.push("");
            }
          }
          for (let i = 0, len = data.results.length; i < len; i += 1) {
            ids.push(data.results[i]._id);
          }
          arr[$this.state.activeStep] = first + "," + last;

          $this.setState({ ranges: arr }, () => {
            $this.setState({ loading: false, init: true });
            $this.setState({
              openMessage: true,
              message: "Hoàn tất truy xuất dữ liệu.",
            });
          });
        }
      );
    } else {
      //console.log('run here');
      let m = "Hoàn tất truy xuất dữ liệu. Không có dữ liệu phù hợp yêu cầu!!!";
      
      if (data.status !== "success") {
        m = "Data loading error";
      }

      $this.setState({
        loading: false,
        init: true,
        openMessage: true,
        message: m,
        data: [],
      });
    }
  }

  handleFindCache(query) {
    let $this = this,
        cache = $this.state.cache,
        item;

    cache.map((x) => {
      if (
        x.query.itemPerPage === query.itemPerPage &&
        x.query.firstId === query.firstId &&
        x.query.lastId === query.lastId
      ) {
        item = x;
      }
    });

    return item;
  }

  handleSearch() {
    let $this = this;
    $this.setState(
      {
        loading: true,
        activeStep: 0,
        totalSteps: 1,
        totalRecord: 0,
        lastId: 0,
        firstId: 0,
      },
      () => {
        let f = $this.state.filter;
        if (f === "" || f === undefined) {
          f = {};
        } else {
          f = JSON.parse(f);
        }
        f.search = $this.state.keyword;

        let s = $this.state.sorts;
        if (s === "" || s === undefined) {
          s = null;
        } else {
          s = JSON.stringify(JSON.parse(s));
        }

        Utils.getListDataSortWithFilter(
          $this.props.apiTable,
          1,
          $this.state.itemPerPage,
          "0,0",
          JSON.stringify(f),
          s,
          1,
          false,
          (data) => {
            $this.handleData(data);
          }
        );
      }
    );
  }

  handleNextButtonClick = () => {
    if (this.state.loading) return true;
    let $this = this;
    let f = $this.state.filter;
    if (f === "" || f === undefined) {
      f = {};
    } else {
      f = JSON.parse(f);
    }
    f.search = $this.state.keyword;

    let s = $this.state.sorts;
    if (s === "" || s === undefined) {
      s = null;
    } else {
      s = JSON.stringify(JSON.parse(s));
    }

    $this.setState(
      {
        activeStep: $this.state.activeStep + 1,
        loading: true,
      },
      () => {
        let cache = $this.state.cache,
          query = {},
          cacheItem;

        if ($this.state.ranges[$this.state.activeStep] !== "") {
          query = {
            itemPerPage: $this.state.itemPerPage,
            firstId: $this.state.ranges[$this.state.activeStep].split(",")[0],
            lastId: $this.state.ranges[$this.state.activeStep].split(",")[1],
          };
          cacheItem = $this.handleFindCache(query);
        }

        if (cacheItem) {
          cacheItem.data.map((x) => {
            x.selected = $this.state.selectedAll ? true : false;
          });
          $this.setState(
            {
              data: cacheItem.data,
              lastId: cacheItem.query.lastId,
              firstId: cacheItem.query.firstId,
            },
            () => {
              $this.setState({ loading: false });
            }
          );
        } else {
          Utils.getListDataSortWithFilter(
            $this.props.apiTable,
            1,
            $this.state.itemPerPage,
            $this.state.firstId + "," + $this.state.lastId,
            JSON.stringify(f),
            s,
            1,
            false,
            (data) => {
              $this.handleData(data);
            }
          );
        }
      }
    );
  }

  handleBackButtonClick = () => {
    if (this.state.loading) return true;
    let $this = this;

    let f = $this.state.filter;
    if (f === "" || f === undefined) {
      f = {};
    } else {
      f = JSON.parse(f);
    }
    f.search = $this.state.keyword;

    let s = $this.state.sorts;
    if (s === "" || s === undefined) {
      s = null;
    } else {
      s = JSON.stringify(JSON.parse(s));
    }

    $this.setState(
      {
        activeStep: this.state.activeStep - 1,
        loading: true,
      },
      function () {
        let cache;

        cache = $this.handleFindCache({
          itemPerPage: $this.state.itemPerPage,
          firstId: $this.state.ranges[$this.state.activeStep].split(",")[0],
          lastId: $this.state.ranges[$this.state.activeStep].split(",")[1],
        });

        if (cache) {
          cache.data.map((x) => {
            x.selected = $this.state.selectedAll ? true : false;
          });
          $this.setState({
            data: cache.data,
            firstId: cache.query.firstId,
            lastId: cache.query.lastId,
            loading: false,
          });
        } else {
          //console.log($this.state.ranges);
          if ($this.state.ranges[$this.state.activeStep] === "") {

            Utils.getListDataSortWithFilter(
              $this.props.apiTable,
              $this.state.totalSteps - $this.state.activeStep,
              $this.state.itemPerPage,
              $this.state.firstId + "," + $this.state.lastId,
              JSON.stringify(f),
              s,
              1,
              false,
              function (data) {
                $this.handleData(data);
              }
            );
          } else {
            Utils.getListDataSortWithFilter(
              $this.props.apiTable,
              1,
              $this.state.itemPerPage,
              $this.state.ranges[$this.state.activeStep],
              JSON.stringify(f),
              s,
              0,
              false,
              function (data) {
                $this.handleData(data);
              }
            );
          }
        }
      }
    );
  }

  handleFirstPageButtonClick = (event) => {
    if (this.state.loading) return true;
    var $this = this;

    let filter = $this.state.filter !== undefined ? $this.state.filter : "";
    if ($this.state.keyword !== "" && filter !== "") {
      filter = $this.state.keyword + "," + filter;
    } else if (filter === "") {
      filter = $this.state.keyword;
    }

    $this.setState(
      {
        activeStep: 0,
        loading: true,
      },
      function () {
        let cache;
        cache = $this.state.cache[0];
        if (cache) {
          cache.data.map((x) => {
            x.selected = $this.state.selectedAll ? true : false;
          });
          $this.setState({
            data: cache.data,
            firstId: cache.query.firstId,
            lastId: cache.query.lastId,
            loading: false,
          });
        }
      }
    );
  }

  handleLastPageButtonClick = (event) => {
    if (this.state.loading) return true;
    let $this = this;

    let f = $this.state.filter;
    if (f === "" || f === undefined) {
      f = {};
    } else {
      f = JSON.parse(f);
    }
    f.search = $this.state.keyword;

    let s = $this.state.sorts;
    if (s === "" || s === undefined) {
      s = null;
    } else {
      s = JSON.stringify(JSON.parse(s));
    }

    $this.setState(
      {
        activeStep: $this.state.totalSteps - 1,
        loading: true,
      },
      function () {
        if ($this.state.ranges[$this.state.activeStep] === "") {
          
          Utils.getListDataSortWithFilter(
            $this.props.apiTable,
            1,
            $this.state.totalRecord -
              $this.state.itemPerPage * ($this.state.totalSteps - 1),
            "0,0",
            JSON.stringify(f),
            s,
            1,
            true,
            function (data) {
              $this.handleData(data);
            }
          );
        } else {
          let cache = $this.state.cache,
            query = {
              itemPerPage: $this.state.itemPerPage,
              firstId: $this.state.ranges[$this.state.activeStep].split(",")[0],
              lastId: $this.state.ranges[$this.state.activeStep].split(",")[1],
            },
            cacheItem = $this.handleFindCache(query);

          if (cacheItem) {
            cacheItem.data.map((x) => {
              x.selected = $this.state.selectedAll ? true : false;
            });
            $this.setState(
              {
                data: cacheItem.data,
                lastId: cacheItem.query.lastId,
                firstId: cacheItem.query.firstId,
              },
              function () {
                $this.setState({ loading: false });
              }
            );
          } else {
            Utils.getListDataSortWithFilter(
              $this.props.apiTable,
              1,
              $this.state.itemPerPage,
              "0,0",
              JSON.stringify(f),
              s,
              1,
              true,
              function (data) {
                $this.handleData(data);
              }
            );
          }
        }
      }
    );
  }

  handleChangeRowsPerPage = (event) => {
    var $this = this;
    this.setState(
      { cache: [], ranges: [], itemPerPage: event.target.value },
      function () {
        $this.setState({ loading: true }, function () {
          let s = $this.props.apiSort;
          if (s === "" || s === undefined) {
            s = null;
          }
          Utils.getListDataSortWithFilter(
            $this.props.apiTable,
            1,
            $this.state.itemPerPage,
            "0,0",
            "",
            s,
            1,
            false,
            (data) => {
              $this.handleData(data);
            }
          );
        });
      }
    );
  }

  onEdit = (item) => () => {
    let $this = this;
    $this.props.router.history.push("edit/" + item._id);
  }

  onDelete = (item) => () => {
    const $this = this;
    $this.setState({ loading: true });

    Utils._delete($this.props.apiSingle, item, (res) => {
      if (res.status === "success") {
        let s = $this.props.apiSort;
        if (s === "" || s === undefined) {
          s = null;
        }
        Utils.getListDataSortWithFilter(
          $this.props.apiTable,
          1,
          $this.state.itemPerPage,
          "0,0",
          "",
          s,
          1,
          false,
          (data) => {
            $this.handleData(data);
          }
        );
      } else {
        $this.setState({ loading: false, failed: true, message: res.message });
      }
    });
  }

  onDuplicate = (obj) => () => {
    var $this = this;
    //console.log(obj);
    Utils.getSingleData($this.props.apiSingle, obj._id, function (data) {
      if (data.status === "success" && data.results !== null) {
        var new_obj = {},
          item = data.results;
        for (var property in item) {
          if (item.hasOwnProperty(property)) {
            if (
              $this.props.apiDuplicateIgnore === undefined ||
              ($this.props.apiDuplicateIgnore !== undefined &&
                $this.props.apiDuplicateIgnore.indexOf(property) === -1)
            ) {
              if (property !== "_id") {
                if (
                  property.indexOf("titleVN") !== -1 ||
                  property.indexOf("nameVN") !== -1
                ) {
                  new_obj[property] = item[property] + "-" + Date.now();
                } else if (property === "title" || property === "name") {
                  new_obj[property] = item[property] + "-" + Date.now();
                } else {
                  new_obj[property] = item[property];
                }
              }
              if (property === "code") {
                new_obj[property] = item[property] + "-" + Date.now();
              }
              if (property === "students") {
                new_obj[property] = item[property] + "-" + Date.now();
              }
              if (property === "url") {
                if (obj.title !== undefined) {
                  new_obj.url = Utils.getFriendlyURL(new_obj.title);
                } else if (obj.name !== undefined) {
                  new_obj.url = Utils.getFriendlyURL(new_obj.name);
                } else if (obj.nameVN !== undefined) {
                  new_obj.url = Utils.getFriendlyURL(new_obj.nameVN);
                } else {
                  new_obj.url = Utils.getFriendlyURL(new_obj.titleVN);
                }
              }
              if (property === "urlVN") {
                if (obj.title !== undefined) {
                  new_obj.urlVN = Utils.getFriendlyURL(new_obj.title);
                } else if (obj.name !== undefined) {
                  new_obj.urlVN = Utils.getFriendlyURL(new_obj.name);
                } else if (obj.nameVN !== undefined) {
                  new_obj.urlVN = Utils.getFriendlyURL(new_obj.nameVN);
                } else {
                  new_obj.urlVN = Utils.getFriendlyURL(new_obj.titleVN);
                }
              }
              if (property === "urlEN") {
                if (obj.titleEN !== undefined) {
                  new_obj.urlEN = Utils.getFriendlyURL(new_obj.titleEN);
                } else if (obj.nameEN !== undefined) {
                  new_obj.urlEN = Utils.getFriendlyURL(new_obj.nameEN);
                }
              }
            }
          }
        }
        Utils._add($this.props.apiSingle, new_obj, function (res) {
          if (res.status === "success") {
            $this.props.router.history.push("edit/" + res.data._id);
          } else {
            $this.setState({
              failed: true,
              message: res.message,
              openMessage: true,
            });
          }
        });
      }
    });
  }

  onSelectClick = (item, index) => (e) => {
    let $this = this,
      data = $this.state.data,
      numSelected = $this.state.numSelected;
    data[index].selected = e.target.checked;
    e.target.checked ? numSelected++ : numSelected--;

    $this.setState(
      {
        data: data,
        numSelected: numSelected,
        selectedAll: numSelected == $this.state.totalRecord,
      },
      function () {
        $this.props.onChangeSelect(data.filter((x) => x.selected));
      }
    );
  }

  onSelectAllClick = (e) => {
    let $this = this,
      data = $this.state.data,
      numSelected = $this.state.numSelected;

    data.map((x, i) => {
      data[i].selected = e.target.checked;
    });
    numSelected = e.target.checked ? parseInt($this.state.totalRecord) : 0;

    $this.setState(
      {
        data: data,
        numSelected: numSelected,
        selectedAll: e.target.checked,
      },
      function () {
        $this.props.onChangeSelect(data.filter((x) => x.selected));
      }
    );
  }

  onChangeAction = (e) => {
    let i = e.target.value;
    this.setState({
      bulkSelected: i,
    });
  }

  handleBulk = () => {
    const $this = this;
    let data = $this.state.data.filter((x) => x.selected);
    if ($this.state.bulkSelected === "") {
      window.alert("Không có hành động được chọn!!!");
    } else if (data.length) {
      $this.props.apiBulk[$this.state.bulkSelected].action(data);
      $this.setState(
        {
          bulkSelected: "",
        },
        () => {
          $this.handleSearch();
        }
      );
    } else {
      window.alert("Không có dữ liệu được chọn!!!");
    }
  }

  handleCloseMessage = () => {
    this.setState({ openMessage: false });
  }

  handleDragStart = (item, index) => (e) => {
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index,
    });
  }

  handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }

  handleDragEnd = (e) => {
    //console.log(e);
  }

  handleDrop = (item, index) => (e) => {
    //drop
    e.preventDefault();
    e.stopPropagation();
    let $this = this,
      data = $this.state.data,
      old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex, 1);
    data.splice(index, 0, old);
    //console.log(data);
    for (let i = 0, len = data.length; i < len; i += 1) {
      data[i].order = parseInt($this.state.activeStep * config.itemPerPage) + i;
    }
    this.setState(
      {
        data: data,
      },
      () => {
        for (let i = 0, len = data.length; i < len; i += 1) {
          Utils._update($this.props.apiSingle, data[i], function (res) {
            //console.log(res);
          });
        }
      }
    );
  }

  onFileChange = (event) => {
    var $this = this,
      filetypes = $this.props.acceptedTypes,
      file = event.target.files[0];
    if (file.type) {
      if (filetypes.indexOf(file.type) !== -1) {
        $this.readExcelFile(file);
      } else {
        alert(
          file.name +
            "\nFile type not supported\nOnly support " +
            $this.props.acceptedTypes +
            "!!!"
        );
      }
    } else {
      let t = file.name.split(".").pop();
      if (filetypes.indexOf("." + t) !== -1) {
        $this.readExcelFile(file);
      } else {
        alert(
          file.name +
            "\nFile type not supported\nOnly support " +
            $this.props.acceptedTypes +
            "!!!"
        );
      }
    }
  }

  readExcelFile(file) {
    const $this = this;
    var reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;
      data = new Uint8Array(data);
      var wb = XLSX.read(data, { type: "array" });

      var sheet_name_list = wb.SheetNames;
      if (typeof $this.props.apiImport === "function")
        $this.props.apiImport(
          XLSX.utils.sheet_to_json(wb.Sheets[sheet_name_list[0]])
        );
    };

    reader.readAsArrayBuffer(file);
  }

  handleExportExcel = (event) => {
    const $this = this;

    $this.setState(
      { loading: true, exportType: event.target.value },
      function () {
        let heading = [["CodeBACs " + $this.props.apiTable.toUpperCase()]],
          head = [],
          data = [],
          arr = [];

        const node = $this.tableRef.current;
        let tbody = node.getElementsByTagName("tbody")[0];

        switch ($this.state.exportType) {
          case 1:
            $this.props.dataMap.thead.map((x) => {
              head.push(x.title);
              arr.push(x.value);
            });

            heading.push(head);

            let data = [];
            let start = 1;
            if ($this.props.showCheckBox) {
              start = 2;
            }
            [...tbody.children].map((x, i) => {
              let obj = {};
              for (let n = start, len = arr.length + start; n < len; n++) {
                let imgs = x.children[n].querySelectorAll("img");
                if (imgs.length) {
                  obj[arr[n - start]] = imgs[0].getAttribute("src");
                } else {
                  obj[arr[n - start]] = x.children[n].innerText;
                }
              }
              data.push(obj);
            });
            $this.exportToExcelFile(data, heading, arr, function () {
              $this.setState({ loading: false });
            });
            break;

          case 2:
            let api = "export";
            Utils._post(
              api,
              {
                type: "xlsx",
                target: $this.props.apiTable,
              },
              function (response) {
                //console.log(response);
                if (response.status === "success") {
                  $this.setState(
                    {
                      loading: false,
                    },
                    function () {
                      for (
                        let i = 0, len = response.files.length;
                        i < len;
                        i++
                      ) {
                        window.open(response.files[i], "_blank");
                      }
                    }
                  );
                } else {
                  $this.setState(
                    {
                      loading: false,
                    },
                    function () {
                      alert("Export failed!!!");
                    }
                  );
                }
              }
            );
            break;
          default:
            break;
        }
      }
    );
  }

  sequentiallyExport(source, total, current, heading, arr) {
    const $this = this;
    let data = [];
    if (current < total - 1) {
      for (let i = 0, leni = 100000; i < leni; i++) {
        let x = source[i + current * 100000],
          obj = {};
        for (let property in x) {
          if (x.hasOwnProperty(property)) {
            //console.log(item[property]);
            if (Array.isArray(x[property])) {
              obj[property] = x[property].join();
            } else {
              obj[property] = x[property];
            }
          }
        }
        data.push(obj);
      }
      $this.exportToExcelFile(data, heading, arr, function () {
        $this.sequentiallyExport(source, total, current + 1, heading, arr);
      });
    } else {
      for (
        let i = 0, leni = source.length - (total - 1) * 100000;
        i < leni;
        i++
      ) {
        let x = source[i + current * 100000],
          obj = {};
        for (let property in x) {
          if (x.hasOwnProperty(property)) {
            //console.log(item[property]);
            if (Array.isArray(x[property])) {
              obj[property] = x[property].join();
            } else {
              obj[property] = x[property];
            }
          }
        }
        data.push(obj);
      }
      $this.exportToExcelFile(data, heading, arr, function () {
        $this.setState({ loading: false });
      });
    }
  }

  exportToExcelFile(data, title, head, callback) {
    const $this = this;
    var ws = XLSX.utils.aoa_to_sheet(title);
    XLSX.utils.sheet_add_json(ws, data, {
      header: head,
      skipHeader: true,
      origin: 2,
    });

    //var ws = XLSX.utils.json_to_sheet(data);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CodeBacsSheet");
    XLSX.writeFile(wb, "data_codebacs_" + Date.now() + ".xlsx", {
      bookType: "xlsx",
      type: "array",
    });
    if (typeof callback === "function") callback();
    if (typeof $this.props.onExportExcelComplete === "function")
      $this.props.onExportExcelComplete();
  }

  render() {
    const $this = this;
    const classes = $this.props.classes;
    const head = $this.props.dataMap.thead;
    const dataMap = $this.props.dataMap.tbody;
    const rowsPerPage = parseInt($this.state.itemPerPage);
    const page = parseInt($this.state.activeStep);
    const count = parseInt($this.state.totalRecord);
    const hasSelected = $this.props.showCheckBox;

    let numSelected = $this.state.numSelected;

    return (
      <div
        className={classNames(
          classes.tableWrapper,
          !$this.state.loading ? "" : classes.tableLoading
        )}
      >
        <div className={classes.tableExcelToolbar}>
          {$this.props.apiImport && (
            <div className="upload-zone file-control">
              <TextField
                className="fake-inputfile"
                placeholder="Import from GetFly"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachmentIcon></AttachmentIcon>
                    </InputAdornment>
                  ),
                }}
              />
              <input
                onChange={this.onFileChange}
                type="file"
                accept={$this.props.acceptedTypes}
              ></input>
            </div>
          )}
          <Select
            style={{ minWidth: 150, marginRight: 15, textIndent: 15 }}
            value={$this.state.exportType}
            onChange={$this.handleExportExcel}
          >
            <MenuItem value={0} disabled>
              Export Tool
            </MenuItem>
            <MenuItem value={1}>Export This Page</MenuItem>
            {/* <MenuItem value={2}>Export Whole Table</MenuItem> */}
            <MenuItem value={2}>Export From Database</MenuItem>
          </Select>
        </div>
        {count > 0 ? (
          <RootRef rootRef={this.tableRef}>
            <Table className={classes.tableResponsive}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {hasSelected && (
                    <TableCell>
                      <Checkbox
                        indeterminate={numSelected > 0 && numSelected < count}
                        checked={numSelected === count}
                        onChange={$this.onSelectAllClick}
                      />
                    </TableCell>
                  )}
                  <TableCell>STT</TableCell>
                  {head.map((x, i) => {
                    return <TableCell key={i}>{x.title}</TableCell>;
                  })}
                  <TableCell className={classes.cellCommand}>
                    {/* <Icon>gavel</Icon> */}
                    <GavelIcon></GavelIcon>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {$this.state.data.map((x, i) => {
                  let inputProps = {
                    draggable: true,
                    onDragStart: $this.handleDragStart(
                      x,
                      parseInt($this.state.activeStep * config.itemPerPage) + i
                    ),
                    onDragOver: $this.handleDragOver,
                    onDragEnd: $this.handleDragEnd,
                    onDrop: $this.handleDrop(
                      x,
                      parseInt($this.state.activeStep * config.itemPerPage) + i
                    ),
                  };
                  return (
                    <TableRow
                      className={$this.props.apiDragOrder ? "draggable" : ""}
                      key={x._id}
                      {...($this.props.apiDragOrder ? inputProps : {})}
                    >
                      {hasSelected && (
                        <TableCell>
                          <Checkbox
                            checked={x.selected}
                            onChange={$this.onSelectClick(x, i)}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {parseInt(
                          $this.state.activeStep * $this.state.itemPerPage
                        ) +
                          i +
                          1}
                      </TableCell>
                      {head.map((n, y) => {
                        //console.log(dataMap(x));
                        return (
                          <TableCell key={x._id + "-" + y}>
                            {dataMap(x)[n.value]}
                          </TableCell>
                        );
                      })}
                      <TableCell className={classes.cellCommand}>
                        {$this.props.apiActions &&
                          $this.props.apiActions.map((y, i) => {
                            return (
                              <span key={i} data-row={JSON.stringify(x)}>
                                {y}
                              </span>
                            );
                          })}
                        {$this.props.apiDuplicate && (
                          <IconButton
                            className={classes.button}
                            aria-label="Sao chép"
                            onClick={$this.onDuplicate(x)}
                          >
                            <DuplicateIcon style={{ fontSize: 20 }} />
                          </IconButton>
                        )}
                        <IconButton
                          className={classes.button}
                          aria-label="Cập nhật"
                          onClick={$this.onEdit(x)}
                        >
                          <EditIcon style={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                          className={classes.button}
                          aria-label="Xóa"
                          onClick={$this.onDelete(x)}
                        >
                          <DeleteIcon style={{ fontSize: 20 }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {$this.props.apiBulk !== undefined && (
                    <TableCell colSpan={4}>
                      <Select
                        style={{ minWidth: 150, marginRight: 15 }}
                        value={this.state.bulkSelected}
                        onChange={this.onChangeAction}
                      >
                        <MenuItem value="" disabled>
                          Action ...
                        </MenuItem>
                        {$this.props.apiBulk.map((x, i) => {
                          return (
                            <MenuItem key={i} value={i}>
                              {x.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleBulk}
                      >
                        Apply
                      </Button>
                    </TableCell>
                  )}
                  <TableCell
                    colSpan={
                      head.length +
                      (hasSelected
                        ? $this.props.apiBulk !== undefined
                          ? -1
                          : 3
                        : $this.props.apiBulk !== undefined
                        ? -2
                        : 2)
                    }
                  >
                    <div className={classNames(classes.tablePaging)}>
                      <span>Dữ liệu/trang:</span>
                      <Select
                        onChange={this.handleChangeRowsPerPage}
                        value={rowsPerPage}
                        inputProps={{
                          name: "rowsPerPage",
                          id: "rowsPerPage",
                        }}
                        className={classes.tableSelect}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <span>
                        {page * rowsPerPage + 1}-
                        {count <= page * rowsPerPage + rowsPerPage
                          ? count
                          : page * rowsPerPage + rowsPerPage}{" "}
                        of {count}
                      </span>
                      <IconButton
                        onClick={this.handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="First Page"
                      >
                        {theme.direction === "rtl" ? (
                          <LastPageIcon />
                        ) : (
                          <FirstPageIcon />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={this.handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="Previous Page"
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={this.handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Next Page"
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </IconButton>
                      <IconButton
                        onClick={this.handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="Last Page"
                      >
                        {theme.direction === "rtl" ? (
                          <FirstPageIcon />
                        ) : (
                          <LastPageIcon />
                        )}
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </RootRef>
        ) : (
          !$this.state.loading && (
            <SnackbarContent
              className={classes.messageRow}
              aria-describedby="message-id"
              message={
                <span id="message-id">
                  {<WarningIcon className={classes.messageIcon}></WarningIcon>}{" "}
                  Không có dữ liệu phù hợp với yêu cầu!!!
                </span>
              }
            />
          )
        )}
        {
          <div className={classes.loadingSpinner}>
            <CircularProgress></CircularProgress>
          </div>
        }
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={$this.state.openMessage}
          onClose={$this.handleCloseMessage}
          autoHideDuration={3000}
        >
          <SnackbarContent
            className={classes.errorMessage}
            aria-describedby="message-id"
            message={<span id="message-id">{$this.state.message}</span>}
            onClose={$this.handleCloseMessage}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={$this.handleCloseMessage}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    );
  }
}

ReactTable.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ReactTable);
