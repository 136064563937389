import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import { GridList, GridListTile, GridListTileBar,Tooltip} from '@material-ui/core';
import {Typography, Button, IconButton, Toolbar, TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {List, ListItem, ListItemText} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import WebConfig from '../api/config';

import classNames from 'classnames';

import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import theme, {styles} from '../layout/theme';
import Utils from '../api/api';
import UploadItem from './shares/react_upload';
import ReactLazyCardMedia from './shares/react_lazy_card';

import '../assets/scss/upload.scss';

var config = WebConfig[process.env.NODE_ENV];

class MediaLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      root: '',
      folders: [],
      files: [],
      folder: '',
      open: false,
      loading: true
    };
  }
  componentDidMount() {
    var $this = this;
    if ($this.state.root === '' || $this.state.root === undefined) {
      Utils.getFiles('', function (data) {
        if (data.status == 'success' && data.results.length) {

          //add selected field
          data.results.files && data
            .results
            .files
            .map(x => {
              x.selected = false;
            });

          $this.setState({
            folders: data.results,
            files: data.results.files
          }, function () {
            $this
              .setState({
                loading: false
              }, function () {
                //console.log($this.state.files);
              });
          });
        }
      });
    }
  }
  handleFolderClick(event, index) {
    var $this = this;
    var _root = this.state.folders[index].full;
    if (_root === '')
      _root = this.state.folders[index].folder;
    this
      .setState({
        root: _root,
        folders: [],
        files: [],
        loading: true
      }, function () {
        Utils.getFiles($this.state.root, function (data) {
          if (data.status === 'success' && data.results !== null) {

            data.results.files && data
              .results
              .files
              .map(x => {
                x.selected = false;
              });

            $this.setState({
              folders: data.results.folders,
              files: data.results.files
            }, function () {
              //console.log($this.state.files);
              $this.setState({loading: false});
            });
          }
        });
      });
  }
  handleRefresh() {
    var $this = this;
    Utils.getFiles($this.state.root, function (data) {
      if (data.status === 'success' && data.results !== null) {
        data.results.files && data
          .results
          .files
          .map(x => {
            x.selected = false;
          });
        if ($this.state.root === '') {
          $this.setState({folders: data.results, files: data.results.files});
        } else {
          $this.setState({folders: data.results.folders, files: data.results.files});
        }
      }
    });
  }
  handleBack(event, value) {
    var params,
      $this = this;
    if (value.indexOf('/') === -1) {
      params = '';
      //this.context.router.history.push('/media/'+params);
      this.setState({
        root: params
      }, function () {
        Utils
          .getFiles('', function (data) {
            if (data.status == 'success' && data.results.length) {
              $this.setState({folders: data.results, files: []});
            }
          });
      });
    } else {
      params = value.split('/');
      params.pop();
      params = params.join('/');
      this.setState({
        root: params
      }, function () {
        Utils
          .getFiles($this.state.root, function (data) {
            if (data.status == 'success' && data.results !== null) {
              $this.setState({folders: data.results.folders, files: data.results.files});
            }
          });
      });
    }
  }
  handleDelete(value, index) {
    var $this = this;
    var file = value.replace(config.api, '');
    Utils.delete(file, function () {
      $this.setState({
        files: $this
          .state
          .files
          .filter(x => x.fullpath !== value)
      });
    });
  }
  handleCopy(fullpath, index) {
    if (typeof this.props.onSelectChange === 'function') {
      this
        .props
        .onSelectChange(fullpath);
    } else {
      alert(config.api + fullpath);
      // var el = document.createElement('textarea');
      // el.classList.add('visible-hide'); el.textContent = fullpath.toString();
      // console.log(fullpath); document.body.appendChild(el); var selection =
      // document.getSelection(); var range = document.createRange();
      // range.selectNode(el); selection.removeAllRanges(); selection.addRange(range);
      // console.log('copy success', document.execCommand('copy'));
      // selection.removeAllRanges(); //alert('Đã copy đường dẫn hình ảnh');
      // document.body.removeChild(el);
    }
  }
  onUploadComleted() {
    //console.log('upload completed');
    this.handleRefresh();
  }
  handleClickOpen() {
    this.setState({open: true});
  }
  handleClose() {
    this.setState({open: false});
  }
  handleFolderName(e) {
    this.setState({folder: e.target.value});
  }
  handleInputFolder() {
    var $this = this;
    var folder = $this.state.root === ''
      ? $this.state.folder
      : $this.state.root + '/' + $this.state.folder;
    Utils.createFolder(folder, function (res) {
      //console.log(res);
      $this.handleClose();
      $this.handleRefresh();
    });
  }
  createFolder() {
    var $this = this;
    this.handleClickOpen();
    //console.log($this.state);
  }
  handleSelectItem(item, index) {
    var $this = this,
      files = $this.state.files;
    item.selected = !item.selected;
    files[index] = item;
    this.setState({
      files: files
    }, function () {
      if (typeof this.props.onMultiSelectChange === 'function') {
        this.props.onMultiSelectChange(files.filter(x => x.selected === true));
      }
    });
  }
  getFileExtensionIcon(tile) {
    var arr = tile
        .file
        .split('.'),
      ext = arr[arr.length - 1];
    switch (ext) {
      case 'pdf':
        return (
          <span className='fa fa-file-pdf-o'></span>
        );
      case 'doc':
      case 'docx':
        return (
          <span className='fa fa-file-word-o'></span>
        );
      case 'ppt':
      case 'pptx':
        return (
          <span className='fa fa-file-powerpoint-o'></span>
        );
      case 'xls':
      case 'xlsx':
        return (
          <span className='fa fa-file-excel-o'></span>
        );
      case 'mp3':
        return (
          <span className='fa fa-file-audio-o'></span>
        );
      case 'mp4':
        return (
          <span className='fa fa-file-video-o'></span>
        );
      default:
        return (
          //<img src={Utils.serverAPI() + tile.fullpath} alt={tile.file}/>
          <ReactLazyCardMedia alt={tile.file} image={ Utils.serverAPI() + tile.fullpath }/>
        );
    }
  }
  makeThumbnail() {

  }
  render() {
    var folders = this.state.folders || [];
    var files = this.state.files || [];
    const classes = this.props.classes;
    return (
      <div className="media-page">
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="secondary"
            aria-label="new folder"
            size="small"
            onClick={() => this.createFolder()}>
            <Icon>create_new_folder</Icon>
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="make thumbnail"
            size="small"
            onClick={() => this.makeThumbnail()}>
            <Icon>camera_roll</Icon>
          </IconButton>
        </Toolbar>
        <Paper className={classNames(classes.loadingOverlay, !this.state.loading && classes.hide)}>
          <CircularProgress className={classes.progressLoading} size={50}/>
        </Paper>
        <Paper className={classes.paper}>
          <List className={classes.folder_view}>
            <ListItem
              key={0}
              className={classNames(classes.folder_view_li, this.state.root === '' && classes.hide)}
              onClick={(event) => this.handleBack(event, this.state.root)}>
              <Avatar>
                <Icon>folder_open</Icon>
              </Avatar>
              <ListItemText primary='...' secondary=''/>
            </ListItem>
            {folders.map((item, index) => (
              <ListItem
                key={index + 1}
                className={classes.folder_view_li}
                onClick={(event) => this.handleFolderClick(event, index)}>
                <Avatar>
                  <Icon>folder_open</Icon>
                </Avatar>
                <ListItemText primary={item.folder} secondary={item.full}/>
              </ListItem>
            ))}
          </List>
        </Paper>
        <Paper className={classes.paper}>
          <UploadItem target={this.state.root} acceptedTypes={['video/mp4','audio/mpeg','image/png','image/jpeg','image/gif','application/pdf','application/msword','application/vnd.ms-excel','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document']} onComplete={()=>this.onUploadComleted()}>
            {files.length===0&&<div className="upload-area"><Typography type="body2">Drag and Drop To Upload Files here ...</Typography></div>}
            <GridList cellHeight={180} className={classes.folder_view} cols={4}>
              {files.map((tile,index) => (
                <GridListTile key={tile.file} className={'grid-file ' + classes.gridListTile}>
                  {
                    this.getFileExtensionIcon(tile)
                  }
                  <GridListTileBar
                    title={tile.file}
                    actionIcon={
                      <Tooltip title={tile.file} placement="top">
                        <div className={classes.folder_view_file_icon_wrapper}>
                          <IconButton className={classes.folder_view_file_icon} onClick={(event)=> this.handleDelete(tile.fullpath,index)}>
                            <Icon style={{fontSize:'18px'}}>delete</Icon>
                          </IconButton>
                          <IconButton className={classes.folder_view_file_icon} onClick={(event)=> this.handleCopy(tile.fullpath,index)}>
                            <Icon style={{fontSize:'18px'}}>touch_app</Icon>
                          </IconButton>
                          <IconButton className={classes.folder_view_file_icon} onClick={(event)=> this.handleSelectItem(tile, index)}>
                            {
                              tile.selected?<Icon style={{fontSize:'18px'}}>done</Icon>:<Icon style={{fontSize:'18px'}}>crop_square</Icon>
                            }
                          </IconButton>
                        </div>
                      </Tooltip>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </UploadItem>
          <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tạo thư mục</DialogTitle>
            <DialogContent>
              <TextField value={this.state.folder} onChange={(event)=>this.handleFolderName(event)} autoFocus margin="dense" id="folder" label="Thư mục" type="text" fullWidth/>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>this.handleClose()} color="default">Quay Lại</Button>
              <Button onClick={()=>this.handleInputFolder()} color="primary">OK</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

MediaLibrary.propTypes = {
  classes: PropTypes.object.isRequired
};
MediaLibrary.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(MediaLibrary);
