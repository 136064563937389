import React, { Component } from 'react';
//import CardMedia from '@material-ui/core/CardMedia';
import Utils from '../../api/api';
import classNames from 'classnames';

const config = {
  // If the image gets within 50px in the Y axis, start the download.
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};
const dummy = Utils.fixImagePath('/data/upload/images/lazy.jpg');

class ReactLazyCardMedia extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   src: this.props.image
    // };
    this.image = React.createRef();
  }
  componentDidMount(){
    var $this = this;
    var observer;

    //console.log($this.image.current.title);

    if (!('IntersectionObserver' in window)) {
      $this.image.current.classList.add('handled');
      $this.image.current.src = $this.image.current.dataset.src;
    } else {

      observer = new IntersectionObserver($this.onIntersection, config);
      observer.observe($this.image.current);

    }
  }
  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.image!==prevProps.image){
      this.image.current.src = this.props.image;
    }
  }
  onIntersection(entries, obs) {
    let entry = entries[0],
        img = entry.target;

    if(img.classList.contains('handled')) {
      obs.disconnect();
    }


    if (entry.intersectionRatio > 0) {

      img.classList.add('handled');
      img.src = img.dataset.src;
      img.classList.add('in');

      obs.unobserve(img);

    }

  }
  render(){
    const $this = this;
    //const classes = $this.props.classes;
    return (
      <img ref={this.image} className={classNames($this.props.className,'fade')} src={dummy} data-src={$this.props.image} alt={$this.props.alt?$this.props.alt:''} title={$this.props.title?$this.props.title:''} />
    );
  }
}

export default ReactLazyCardMedia;
