import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../layout/theme';

class TabContainer extends Component {
  render(){
    return (
      <Typography component="div">
        {this.props.children}
      </Typography>
    );
  }
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default withStyles(styles)(TabContainer);
